import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabase';

interface WallStyle {
  id: string;
  wall_id: string;
  title_color: string;
  wall_name_color: string;
  message_color: string;
  post_background_color: string;
  page_background_color: string;
  header_background_color: string;
  title_font_size: string;
  title_font: string;
  message_font_size: string;
  message_font: string;
  image_aspect_ratio: string;
  image_fit: 'contain' | 'cover';
  cols_sm: number;
  cols_md: number;
  cols_lg: number;
  cols_xl: number;
  cols_2xl: number;
  cols_3xl: number;
  cols_4xl: number;
  cols_5xl: number;
}

interface StyleManagerProps {
  wallId: string;
  onClose: () => void;
}

const ALLOWED_ASPECT_RATIOS = ['1:1', '16:9', '4:3', '3:2', '1:2', '9:16'];
const FONT_OPTIONS = ['Arial', 'Helvetica', 'Times New Roman', 'Courier', 'Verdana', 'Georgia', 'Palatino', 'Garamond', 'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'];

const StyleManager: React.FC<StyleManagerProps> = ({ wallId, onClose }) => {
  const [style, setStyle] = useState<WallStyle | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchStyle = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('wall_styles')
        .select('*')
        .eq('wall_id', wallId)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          // No style found, create a new one with default values
          const newStyle: Omit<WallStyle, 'id'> = {
            wall_id: wallId,
            title_color: '#000000',
            wall_name_color: '#000000',
            message_color: '#000000',
            post_background_color: '#ffffff',
            page_background_color: '#f3f4f6',
            header_background_color: '#ffffff',
            title_font_size: '22px',
            title_font: 'Helvetica',
            message_font_size: '16px',
            message_font: 'Arial',
            image_aspect_ratio: '1:1',
            image_fit: 'cover',
            cols_sm: 2,
            cols_md: 3,
            cols_lg: 3,
            cols_xl: 4,
            cols_2xl: 4,
            cols_3xl: 5,
            cols_4xl: 6,
            cols_5xl: 6,
          };
          setStyle(newStyle as WallStyle);
          // Don't save the style here, we'll do it when the user clicks "Save"
        } else {
          setError(`Error fetching style: ${error.message}`);
        }
        return;
      }

      if (data) {
        setStyle(data);
      }
    } catch (err) {
      setError(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
  }, [wallId]);

  useEffect(() => {
    fetchStyle();
  }, [fetchStyle]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    // Validate color inputs
    if (name.includes('color') && !/^#[0-9A-Fa-f]{6}$/.test(value)) {
      setError(`Invalid color format for ${name}. Use #RRGGBB format.`);
      return;
    }

    // Validate font size inputs
    if (name.includes('font_size') && !/^\d+(\.\d+)?(px|em|rem|%)$/.test(value)) {
      setError(`Invalid font size format for ${name}. Use a number followed by px, em, rem, or %.`);
      return;
    }

    // Validate aspect ratio
    if (name === 'image_aspect_ratio' && !ALLOWED_ASPECT_RATIOS.includes(value)) {
      setError('Invalid aspect ratio. Choose from the provided options.');
      return;
    }

    setStyle(prevStyle => ({
      ...prevStyle!,
      [name]: value,
    }));
    setError(null);
  };

  const saveStyle = async (styleToSave: WallStyle = style!) => {
    if (!styleToSave) return;

    try {
      const { data, error } = await supabase
        .from('wall_styles')
        .upsert({ ...styleToSave, wall_id: wallId })
        .select()
        .single();

      if (error) {
        if (error.code === '23505') { // Unique constraint violation
          // The style already exists, so let's update it instead
          const { data: updateData, error: updateError } = await supabase
            .from('wall_styles')
            .update({ ...styleToSave })
            .eq('wall_id', wallId)
            .select()
            .single();

          if (updateError) {
            setError(`Error updating style: ${updateError.message}`);
          } else {
            setStyle(updateData);
            setError(null);
          }
        } else {
          setError(`Error saving style: ${error.message}`);
        }
      } else {
        setStyle(data);
        setError(null);
      }
    } catch (err) {
      setError(`Unexpected error while saving: ${err instanceof Error ? err.message : String(err)}`);
    }
  };

  if (error) {
    return <div className="text-red-500 mb-4">Error: {error}</div>;
  }

  if (!style) return <div>Loading...</div>;

  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6">
      <h2 className="text-2xl font-semibold mb-4">Wall Style Manager</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h3 className="text-lg font-semibold mb-2">Colors</h3>
          <ColorInput label="Title Color" name="title_color" value={style.title_color} onChange={handleChange} />
          <ColorInput label="Wall Name Color" name="wall_name_color" value={style.wall_name_color} onChange={handleChange} />
          <ColorInput label="Message Color" name="message_color" value={style.message_color} onChange={handleChange} />
          <ColorInput label="Post Background Color" name="post_background_color" value={style.post_background_color} onChange={handleChange} />
          <ColorInput label="Page Background Color" name="page_background_color" value={style.page_background_color} onChange={handleChange} />
          <ColorInput label="Header Background Color" name="header_background_color" value={style.header_background_color} onChange={handleChange} />
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Typography</h3>
          <StyleInput label="Title Font Size" name="title_font_size" value={style.title_font_size} onChange={handleChange} type="text" />
          <StyleSelect label="Title Font" name="title_font" value={style.title_font} onChange={handleChange} options={FONT_OPTIONS} />
          <StyleInput label="Message Font Size" name="message_font_size" value={style.message_font_size} onChange={handleChange} type="text" />
          <StyleSelect label="Message Font" name="message_font" value={style.message_font} onChange={handleChange} options={FONT_OPTIONS} />
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">Image Settings</h3>
        <StyleSelect label="Image Aspect Ratio" name="image_aspect_ratio" value={style.image_aspect_ratio} onChange={handleChange} options={ALLOWED_ASPECT_RATIOS} />
        <StyleSelect label="Image Fit" name="image_fit" value={style.image_fit} onChange={handleChange} options={['contain', 'cover']} />
      </div>
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">Columns per breakpoint</h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl'].map((breakpoint) => (
            <StyleInput
              key={breakpoint}
              label={breakpoint}
              name={`cols_${breakpoint}`}
              value={style[`cols_${breakpoint}` as keyof WallStyle] as number}
              onChange={handleChange}
              type="number"
              min="1"
              max="12"
            />
          ))}
        </div>
      </div>
      <div className="mt-6 flex justify-end space-x-4">
        <button onClick={onClose} className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400">Close</button>
        <button onClick={() => saveStyle()} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Save Style</button>
      </div>
    </div>
  );
};

const StyleInput: React.FC<{
  label: string;
  name: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  min?: string;
  max?: string;
}> = ({ label, name, value, onChange, type, min, max }) => (
  <div className="mb-2">
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    />
  </div>
);

const ColorInput: React.FC<{
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, name, value, onChange }) => (
  <div className="mb-2">
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <div className="flex space-x-2">
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder="#000000"
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
      <input
        type="color"
        value={value}
        onChange={onChange}
        name={name}
        className="h-[38px] w-[38px] p-0 cursor-pointer"
      />
    </div>
  </div>
);

const StyleSelect: React.FC<{
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
}> = ({ label, name, value, onChange, options }) => (
  <div className="mb-2">
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <select
      name={name}
      value={value}
      onChange={onChange}
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

export default StyleManager;
