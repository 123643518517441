import { useState, useEffect, useRef, useCallback } from 'react';
import { supabase } from '../supabase';
import { Post, Wall } from '../types';

export const usePostsData = (wall: Wall | null) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const realtimeSubscriptionRef = useRef<any>(null);

  const fetchPosts = useCallback(async () => {
    if (!wall) return;

    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .eq('wall_id', wall.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPosts(data || []);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to fetch posts. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [wall]);

  const handleRealtimeUpdate = (payload: any) => {
    if (payload.eventType === 'INSERT') {
      setPosts(currentPosts => [payload.new, ...currentPosts]);
    } else if (payload.eventType === 'UPDATE') {
      setPosts(currentPosts => 
        currentPosts.map(post => 
          post.id === payload.new.id ? { ...post, ...payload.new } : post
        )
      );
    } else if (payload.eventType === 'DELETE') {
      setPosts(currentPosts => 
        currentPosts.filter(post => post.id !== payload.old.id)
      );
    }
  };

  const setupSubscription = useCallback(() => {
    if (!wall) return null;

    const subscription = supabase
      .channel(`public:posts:${wall.id}`)
      .on('postgres_changes', 
        { 
          event: 'INSERT', // Only listen for new posts
          schema: 'public', 
          table: 'posts',
          filter: `wall_id=eq.${wall.id}`
        }, 
        handleRealtimeUpdate
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          // When subscription is confirmed, set up a separate channel for updates and deletes
          const updateSubscription = supabase
            .channel(`public:posts:${wall.id}:updates`)
            .on('postgres_changes',
              {
                event: 'UPDATE',
                schema: 'public',
                table: 'posts',
                filter: `wall_id=eq.${wall.id}`
              },
              handleRealtimeUpdate
            )
            .subscribe();

          const deleteSubscription = supabase
            .channel(`public:posts:${wall.id}:deletes`)
            .on('postgres_changes',
              {
                event: 'DELETE',
                schema: 'public',
                table: 'posts',
                filter: `wall_id=eq.${wall.id}`
              },
              handleRealtimeUpdate
            )
            .subscribe();

          // Store all subscriptions
          realtimeSubscriptionRef.current = {
            insert: subscription,
            update: updateSubscription,
            delete: deleteSubscription
          };
        }
      });

    return subscription;
  }, [wall]);

  useEffect(() => {
    if (wall) {
      fetchPosts();
      const subscription = setupSubscription();

      return () => {
        // Clean up all subscriptions
        if (realtimeSubscriptionRef.current) {
          if (realtimeSubscriptionRef.current.insert) {
            realtimeSubscriptionRef.current.insert.unsubscribe();
          }
          if (realtimeSubscriptionRef.current.update) {
            realtimeSubscriptionRef.current.update.unsubscribe();
          }
          if (realtimeSubscriptionRef.current.delete) {
            realtimeSubscriptionRef.current.delete.unsubscribe();
          }
        }
      };
    }
  }, [wall, fetchPosts, setupSubscription]);

  const togglePostVisibility = async (postId: string, currentVisibility: boolean) => {
    try {
      const { error } = await supabase
        .from('posts')
        .update({ is_visible: !currentVisibility })
        .eq('id', postId);

      if (error) throw error;

      // Immediately update the local state
      setPosts(currentPosts => 
        currentPosts.map(post => 
          post.id === postId ? { ...post, is_visible: !currentVisibility } : post
        )
      );
    } catch (error) {
      console.error('Error toggling post visibility:', error);
      setError('Failed to toggle post visibility');
    }
  };

  return {
    posts,
    isLoading,
    error,
    togglePostVisibility,
    realtimeSubscription: realtimeSubscriptionRef.current,
  };
};
