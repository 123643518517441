import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabase';
import EditableField from './EditableField';

interface Post {
  id: string;
  wall_id: string;
  image_url: string;
  original_image_url: string;
  title?: string;
  message?: string;
  resolution: string;
  is_visible: boolean;
}

interface PostListProps {
  wallId: string;
}

const PostList: React.FC<PostListProps> = ({ wallId }) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [selectedPosts, setSelectedPosts] = useState<Set<string>>(new Set());
  const [visibilityFilter, setVisibilityFilter] = useState<'all' | 'visible' | 'hidden'>('all');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPosts = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .eq('wall_id', wallId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to fetch posts. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [wallId]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handleEditPost = async (postId: string, updates: { title?: string; message?: string }) => {
    try {
      const { error } = await supabase
        .from('posts')
        .update(updates)
        .eq('id', postId);

      if (error) throw error;

      // Update the local state
      setPosts(currentPosts =>
        currentPosts.map(post =>
          post.id === postId ? { ...post, ...updates } : post
        )
      );
    } catch (error) {
      console.error('Error updating post:', error);
      setError('Failed to update post');
    }
  };

  const togglePostVisibility = async (postIds: string[], setVisible: boolean) => {
    try {
      const { error } = await supabase
        .from('posts')
        .update({ is_visible: setVisible })
        .in('id', postIds);

      if (error) throw error;

      // Update the local state
      setPosts(currentPosts =>
        currentPosts.map(post =>
          postIds.includes(post.id) ? { ...post, is_visible: setVisible } : post
        )
      );
    } catch (error) {
      console.error('Error toggling post visibility:', error);
      setError('Failed to toggle post visibility');
    }
  };

  const deletePosts = async (postIds: string[]) => {
    if (!window.confirm(`Are you sure you want to delete ${postIds.length} post${postIds.length > 1 ? 's' : ''}?`)) return;

    try {
      console.log('Deleting posts:', postIds);
      const { error } = await supabase
        .from('posts')
        .delete()
        .in('id', postIds);

      if (error) throw error;

      console.log('Posts deleted successfully');
      // Clear selection after deletion
      setSelectedPosts(new Set());
      // Refresh the posts list
      fetchPosts();
    } catch (error) {
      console.error('Error deleting posts:', error);
      setError('Failed to delete posts');
    }
  };

  const togglePostSelection = (postId: string) => {
    setSelectedPosts(current => {
      const newSelection = new Set(current);
      if (newSelection.has(postId)) {
        newSelection.delete(postId);
      } else {
        newSelection.add(postId);
      }
      return newSelection;
    });
  };

  const toggleSelectAll = () => {
    if (selectedPosts.size === filteredPosts.length) {
      setSelectedPosts(new Set());
    } else {
      setSelectedPosts(new Set(filteredPosts.map(post => post.id)));
    }
  };

  const filteredPosts = posts.filter(post => {
    if (visibilityFilter === 'all') return true;
    return visibilityFilter === 'visible' ? post.is_visible : !post.is_visible;
  });

  if (isLoading) return <div>Loading posts...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Manage Posts</h3>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <label className="mr-2">Filter posts:</label>
          <select
            value={visibilityFilter}
            onChange={(e) => setVisibilityFilter(e.target.value as 'all' | 'visible' | 'hidden')}
            className="border rounded px-2 py-1"
          >
            <option value="all">All Posts</option>
            <option value="visible">Visible Posts</option>
            <option value="hidden">Hidden Posts</option>
          </select>
        </div>
        {filteredPosts.length > 0 && (
          <div className="flex items-center">
            <label className="flex items-center mr-4">
              <input
                type="checkbox"
                checked={selectedPosts.size === filteredPosts.length && filteredPosts.length > 0}
                onChange={toggleSelectAll}
                className="mr-2"
              />
              Select All
            </label>
            {selectedPosts.size > 0 && (
              <div className="space-x-2">
                <button
                  onClick={() => togglePostVisibility(Array.from(selectedPosts), true)}
                  className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Show Selected
                </button>
                <button
                  onClick={() => togglePostVisibility(Array.from(selectedPosts), false)}
                  className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                >
                  Hide Selected
                </button>
                <button
                  onClick={() => deletePosts(Array.from(selectedPosts))}
                  className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  Delete Selected
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {filteredPosts.length === 0 ? (
        <p>No posts match the current filter.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredPosts.map((post) => (
            <div key={post.id} className={`border rounded shadow overflow-hidden ${!post.is_visible ? 'opacity-50' : ''}`}>
              <div className="relative">
                <input
                  type="checkbox"
                  checked={selectedPosts.has(post.id)}
                  onChange={() => togglePostSelection(post.id)}
                  className="absolute top-2 left-2 z-10 w-4 h-4"
                />
                <img 
                  src={post.image_url} 
                  alt={post.title || 'Post image'} 
                  className="w-full h-auto"
                  style={{ 
                    aspectRatio: post.resolution === '576x1024' ? '9/16' : post.resolution === '1024x1024' ? '1' : '16/9',
                    objectFit: 'cover'
                  }}
                />
              </div>
              <div className="p-4">
                <EditableField
                  value={post.title || ''}
                  onSave={(newTitle) => handleEditPost(post.id, { title: newTitle })}
                  fieldName="title"
                />
                <EditableField
                  value={post.message || ''}
                  onSave={(newMessage) => handleEditPost(post.id, { message: newMessage })}
                  fieldName="message"
                />
                <div className="flex justify-between mt-2">
                  <button 
                    onClick={() => togglePostVisibility([post.id], !post.is_visible)} 
                    className={`px-3 py-1 ${post.is_visible ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-green-500 hover:bg-green-600'} text-white rounded mr-2`}
                  >
                    {post.is_visible ? 'Hide' : 'Show'}
                  </button>
                  <button 
                    onClick={() => deletePosts([post.id])} 
                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostList;
